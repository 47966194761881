<template>
  <div class="projectDemo">
    <!-- 团队总介绍 -->
    <div class="projectDemo-introduce clearfix">
      <div
        class="pi-box"
        v-for="(item, index) in projectDetailList"
        :key="index"
      >
        <el-image
          class="pi-box-img"
          style="width: 285px; height: 400px; display: block"
          :src="item.img"
          fit="fill"
        ></el-image>
        <!-- :preview-src-list="srcList" -->
        <div class="pi-text">{{ item.title }}</div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pi-pagination">
      <el-pagination
        background
        layout=" prev, pager, next"
        :total="pageCount"
        :page-size="8"
        @current-change="changePage"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios"; //引入axios
export default {
  name: "",
  data() {
    return {
      page: 1,
      pageDataList: [], //内容列表(分页前)
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.queryTitleId();
  },
  methods: {
    queryTitleId() {
      let lang = localStorage.getItem("lang") || "CN";
      axios
        .get("http://edu.siwonet.com:8099/queryTitleId", {
          params: {
            language: lang,
            titleId: this.$route.query.nodeId,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.data.pageDatas) {
              this.pageDataList = res.data.data.pageDatas;
            }
          } else {
            this.$message.error(data.msg);
          }
        });
    },
    //点击分页
    changePage(page) {
      this.page = page;
    },
  },
  computed: {
    // 初始化分页
    pageCount() {
      return this.pageDataList.length;
    },
    projectDetailList() {
      // 分页
      //开始下标： (当前页数-1)*每页条数
      //结束下标： (开始下标+每页条数)-1
      let limit = 8;
      let startIndex = (this.page - 1) * limit;
      let endIndex = startIndex + limit - 1;
      return this.pageDataList.slice(startIndex, endIndex + 1);
    },
    //图片预览列表
    srcList() {
      return this.projectDetailList.map((src) => src.img);
    },
  },
  filters: {},
};
</script>

<style lang="less" scoped>
.projectDemo {
  width: 100%;
  /deep/.el-image-viewer__close {
    color: rgb(255, 255, 255);
  }
  .projectDemo-introduce {
    // min-height: 300px;
    width: 100%;
    .pi-box {
      margin-bottom: 35px;
      // position: relative;
      float: left;
      margin-right: 20px;
      // .el-image__inner{
      //   height: 100%;
      // }
      .pi-text {
        // height: 40px;
        // position: absolute;
        // left: 0;
        // bottom: 0;
        // background: rgba(226, 9, 20, 0.8);
        text-align: center;
        color: #666;
        font-weight: bold;
        padding-top: 5px;
        box-sizing: border-box;
        width: 285px;
        font-size: 16px;
        // line-height: 20px;
        letter-spacing: 1px;
        // display: inline-block;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
    }
    .pi-box:nth-child(4n) {
      margin-right: 0 !important;
    }
  }
  .pi-pagination {
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      color: rgb(255, 255, 255);
      background: #d80930;
    }
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: rgb(255, 255, 255);
      background: #d80930;
    }
  }
}
</style>
